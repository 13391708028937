import './index.scss';

import { PageHeader, Button } from '@arco-design/web-react';
import classNames from 'classnames';
import React, { FC, useContext } from 'react';
import Logo from '../logo';
import { GlobalContext } from '@/routes/context';

const langOpts = [
  { value: 'EN', key: 'en-US' },
  { value: '繁', key: 'zh-HK' },
  { value: '简', key: 'zh-CN' },
];

interface SimpleHeadProps {
  extra?: React.ReactNode;
  style?: React.CSSProperties;
}

const SimpleHead: FC<SimpleHeadProps> = ({ extra, style }) => {
  const { lang = 'en-US', setLang } = useContext(GlobalContext);

  const handChangeLang = (value: string) => {
    setLang?.(value);
  };
  return (
    <PageHeader
      style={style}
      className="simple-head"
      title={<Logo />}
      extra={
        <>
          {extra ? (
            extra
          ) : (
            <div className="login-actions  flex justify-between  items-center">
              {langOpts.map(item => (
                <Button
                  type="secondary"
                  className={classNames('simple-head-actions__button', { 'simple-head-actions__button-active': item.key === lang })}
                  onClick={() => handChangeLang(item.key)}
                  key={item.key}
                >
                  {item.value}
                </Button>
              ))}
            </div>
          )}
        </>
      }
    />
  );
};

export default SimpleHead;
